import * as yup from 'yup';
import IBAN from 'iban';
import dayjs from "dayjs";
import {parseDateString} from "./parseDateString";

const depositAmountSchema = yup.string()
    .test(
        'is-valid-deposit-amount',
        'Die Kautionssumme darf maximal das dreifache der Nettokaltmiete betragen.',
        (value) => {
            const rentAmount = parseFloat(
                // @todo read this from the store
                document.getElementById('start_rentAmount')
                    .value
                    .replace(',', '.'));
            const depositAmount = parseFloat(value.replace(',', '.'));
            return depositAmount <= rentAmount * 3;
        })
    .test(
        'is-less-or-equal-to-7500',
        'Die Kautionssumme darf maximal 7500 Euro betragen.',
        (value) => {
            return parseFloat(value.replace(',', '.')) <= 7500;
        })
    .test(
        'is-more-or-equal-to-300',
        'Die Kautionssumme muss mindestens 300 Euro betragen.',
        (value) => {
            return parseFloat(value.replace(',', '.')) >= 300;
        })
    .test(
        'is-valid-decimal',
        'Die Kautionssumme darf maximal das dreifache der Nettokaltmiete betragen.',
        value => (value).match(/^\d*$/) || (value).match(/^\d*\,{1}\d*$/) ||
            (value).match(/^\d*\.{1}\d*$/),
    );

const landlordEmailSchema = yup.string()
    .email('Bitte geben Sie eine gültige E-Mail-Adresse ein.')
    .nullable()
    .test(
        'no-special-chars',
        'Die E-Mail-Adresse des Vermieters darf keine Umlaute oder andere Sonderzeichen enthalten.',
        (value) => {
            const re = /[üößä]/;
            return !re.test(String(value)
                .toLowerCase());
        }
    )
    .test(
        'is-not-same-as-tenant',
        'Die E-Mail-Adresse des Vermieters sollte nicht mit der des Mieters übereinstimmen.',
        (value) => {
            // @todo read this from the store
            const tenantEmail = document.getElementById('next_email').value;
            return tenantEmail !== value;
        })
    .test(
        'required-if-digital',
        'Die E-Mail des Vermieters ist für die digitale Bürgschaft erforderlich',
        (value) => {
            // @todo read this from the store
            const destination = document.getElementById('next_destAddress').value;
            return destination !== 'd' || value !== '';
        })
    .max(255, 'Ihre E-Mail Adresse darf nicht mehr als 255 Zeichen enthalten.');

const rentAmountSchema = yup.string()
    .test(
        'is-valid-decimal',
        'Bitte geben Sie die Nettokaltmiete in Zahlen ein.',
        value => (value).match(/^\d*$/) || (value).match(/^\d*\,{1}\d*$/) ||
            (value).match(/^\d*\.{1}\d*$/),
    )
    .required('Dieser Wert sollte nicht leer sein.');

const streetNameSchema = requiredMessage => yup.string()
    .test(
        'is-valid-streetname',
        'Der Straßenname darf nur Buchstaben, Bindestriche und einen Punkt enthalten. Bitte prüfen Sie Ihre Angabe. Bei Schwierigkeiten helfen wir gerne telefonisch weiter.',
        value => (value).match(/^([.A-Za-z0-9\-\\“`/À-ÖØ-öø-ÿ ]*)$/),
    )
    .min(2, 'Ein Straßenname besteht aus mindestens 2 Zeichen.')
    .max(50, 'Ein Straßenname besteht aus maximal 50 Zeichen.')
    .required(requiredMessage);

const citySchema = requiredMessage => yup.string()
    .test(
        'is-valid-cityname',
        'Der Stadtname darf nur Buchstaben, Bindestriche und einen Punkt enthalten. Bitte prüfen Sie Ihre Angabe. Bei Schwierigkeiten helfen wir gerne telefonisch weiter.',
        value => (value).match(/^([A-Za-z\-()./ À-ÖØ-öø-ÿ]*)$/),
    )
    .min(2, 'Eine Stadtname besteht aus mindestens 2 Zeichen.')
    .max(50, 'Ein Stadtname besteht aus maximal 50 Zeichen.')
    .required(requiredMessage);

const houseNumberSchema = requiredMessage => yup.string()
    .test(
        'is-valid-housenumber',
        'Eine Hausnummer beginnt mit einer Nummer und hat dann maximal 5 weitere Nummern, Buchstaben, "-", "/", oder Leerzeichen.',
        value => (value).match(/^([0-9][ \-/0-9A-Za-z]{0,5})$/),
    )
    .max(255, 'Eine Hausnummer besteht aus maximal 255 Zeichen.');

export const schema = yup.object()
    .shape({
        start_gender: yup.string()
            .required('Bitte geben Sie eine Anrede an.'),
        start_firstName: yup.string()
            .required('Bitte geben Sie Ihren Vornamen an.')
            .max(255, 'Ihr Name darf nicht aus mehr als 255 Zeichen bestehen.'),
        start_lastName: yup.string()
            .required('Bitte geben Sie Ihren Nachnamen an.')
            .max(255, 'Ihr Name darf nicht aus mehr als 255 Zeichen bestehen.'),
        start_streetName: streetNameSchema(
            'Bitte geben Sie den Straßennamen Ihrer aktuellen Meldeadresse an.'),
        start_houseNumber: houseNumberSchema(
            'Bitte geben Sie die Hausnummer Ihrer aktuellen Meldeadresse an.'),
        start_addressCode: yup.string()
            .required('Bitte geben Sie Ihre Postleitzahl an.')
            .min(5, 'Eine Postleitzahl besteht aus 5 Nummern.')
            .max(5, 'Eine Postleitzahl besteht aus 5 Nummern.')
            .matches(/[0-9]{5}/, 'Eine Postleitzahl besteht aus 5 Nummern.'),
        start_city: citySchema(
            'Bitte geben Sie die Stadt an, in der Sie derzeit gemeldet sind.'),
        start_phone: yup.string()
            .nullable()
            .matches(/^$|^(((((((00|\+?)49)|0)[1-9][0-9]{1,4}))|((((00|\+)49\()|\(0)[1-9][0-9]{1,4}\)))[0-9]{1,7}([0-9]{1,5})?)$/,
                'Bitte geben sie ihre Telefonnummer ohne Leerzeichen und im Format 03012345 oder +493012345 an.'),
        start_birthdate: yup.date()
            .transform(parseDateString)
            .typeError('Bitte geben Sie Ihr Geburtsdatum im Format "dd.mm.jjjj" ein.')
            .required('Bitte geben Sie ihr Geburtsdatum an.')
            .max(dayjs()
                    .subtract(18, 'year')
                    .toDate(),
                'Sie sollten zwischen 18 und 120 Jahren alt sein.')
            .min(dayjs()
                    .subtract(120, 'year')
                    .toDate(),
                'Sie sollten zwischen 18 und 120 Jahren alt sein.'),
        start_email: yup.string()
            .email(
                'Bitte geben Sie eine gültige E-Mail-Adresse ein. Wir benötigen die Email Adresse um Ihnen ihr Angebot zu senden. Wir geben Ihre Adresse nicht weiter.')
            .min(7,
                'Ihre E-Mail-Adresse muss insgesamt mindestens 7 Zeichen lang sein.')
            .max(180, 'Ihre E-Mail-Adresse darf höchstens 180 Zeichen lang sein.')
            .test(
                'no-special-chars',
                'Ihre E-Mail-Adresse darf keine Umlaute oder andere Sonderzeichen enthalten.',
                (value) => {
                    const re = /[üößä]/;
                    return !re.test(String(value)
                        .toLowerCase());
                }
            )
            .required('Bitte geben Sie eine gültige E-Mail-Adresse ein.'),
        start_rentAmount: rentAmountSchema,
        start_depositAmount: depositAmountSchema,
        start_termsAccepted: yup.boolean()
            .oneOf([true], 'Bitte akzeptieren Sie die Datenschutz- und AGB.'),
        next_destAddress: yup.string()
            .required('Sie müssen entweder die Digitale Bürgschaft oder eine Adresse auswählen.'),
        next_depositAmount: yup.string()
            .required('Bitte geben Sie die Höhe der Mietkaution an.'),
        next_moveInDate: yup.date()
            .transform(parseDateString)
            .typeError('Bitte geben Sie ein Datum im Format dd.mm.yyyy ein.')
            .required(
                'Bitte geben Sie das Datum des Mietbeginns laut Mietvertrag an.')
            .max(dayjs()
                    .add(2, 'month')
                    .toDate(),
                'Das Einzugsdatum sollte maximal 6 Monate in der Vergangenheit und 2 Monate in der Zukunft liegen.')
            .min(dayjs()
                    .subtract(6, 'month')
                    .toDate(),
                'Das Einzugsdatum sollte maximal 6 Monate in der Vergangenheit und 2 Monate in der Zukunft liegen.'),
        next_propertyStreetName: streetNameSchema(
            'Bitte geben Sie den Straßennamen Ihrer neuen Wohnung an.'),
        next_propertyHouseNumber: houseNumberSchema(
            'Bitte geben Sie die Hausnummer Ihrer neuen Wohnung an.'),
        next_propertyAddressCode: yup.string()
            .required('Bitte geben Sie die Postleitzahl Ihrer neuen Wohnung an.')
            .min(5, 'Eine Postleitzahl besteht aus 5 Nummern.')
            .max(5, 'Eine Postleitzahl besteht aus 5 Nummern.')
            .matches(/[0-9]{5}/, 'Eine Postleitzahl besteht aus 5 Nummern.'),
        next_propertyCity: citySchema(
            'Bitte geben Sie die Stadt an, in die Sie ziehen.'),
        next_landlordName: yup.string()
            .required(
                'Bitte geben Sie den Firmennamen oder bei privaten Vermietern den Vor- und Nachnamen Ihres Vermieters an.')
            .max(100, 'Ihr Firmenname oder Vor- und Nachname darf nicht länger als 100 Zeichen sein.')
            .matches(/\S+ \S+/, 'Bitte übernehmen Sie den vollständigen Namen der Vermietung aus dem Mietvertrag (bei Personen den Vor- und Nachnamen, bei Firmen die komplette Firmenbezeichnung inkl. Gesellschaftsform. Beispiel: LEG IMMOBILIEN SE).'),
        next_landlordEmail: landlordEmailSchema,
        next_email: yup.string()
            .nullable(),
        next_landlordStreetName: yup.string()
            .when('next_destAddress', (val) => {
                // @todo read this from the store
                if (document.getElementById('next_destAddress').value === 'l') {
                    return streetNameSchema(
                        'Bitte geben Sie den Straßennamen Ihres Vermieters an.');
                } else {
                    return yup.string()
                        .nullable();
                }
            }),
        next_landlordHouseNumber: yup.string()
            .when('next_destAddress', (val) => {
                // @todo read this from the store
                if (document.getElementById('next_destAddress').value === 'l') {
                    return houseNumberSchema(
                        'Bitte geben Sie die Hausnummer Ihres Vermieters an.');
                } else {
                    return yup.string()
                        .nullable();
                }
            }),
        next_landlordAddressCode: yup.string()
            .when('next_destAddress', (val) => {
                // @todo read this from the store
                if (document.getElementById('next_destAddress').value === 'l') {
                    return yup.string()
                        .required('Bitte geben Sie die Postleitzahl Ihres Vermieters an.')
                        .min(5, 'Eine Postleitzahl besteht aus 5 Nummern.')
                        .max(5, 'Eine Postleitzahl besteht aus 5 Nummern.')
                        .matches(/[0-9]{5}/, 'Eine Postleitzahl besteht aus 5 Nummern.');
                } else {
                    return yup.string()
                        .nullable();
                }
            }),
        next_landlordCity: yup.string()
            .when('next_destAddress', (val) => {
                // @todo read this from the store
                if (document.getElementById('next_destAddress').value === 'l') {
                    return citySchema('Bitte geben Sie die Stadt Ihres Vermieters an.');
                } else {
                    return yup.string()
                        .nullable();
                }
            }),
        next_IBAN: yup.string()
            .required('Bitte geben Sie Ihre IBAN an.')
            .test('isValidIban', 'Bitte geben Sie ein korrektes IBAN Format ein.', IBAN.isValid)
            .max(34, 'Eine IBAN besteht aus maximal 34 Zeichen.')
            .matches(/^(AD|AT|AX|BE|CY|DE|EE|ES|FI|FR|GR|IE|IT|LV|LT|LU|MT|MC|NL|PT|SM|SK|SI)/,
                'Leider akzeptieren wir nur IBANs von Andorra, Österreich, Belgien, Zypern, Estland, Finnland, ' +
                'Åland-Inseln, Frankreich, Deutschland, Griechenland, Irland, Italien, Lettland, Litauen, Luxemburg, ' +
                'Malta, Monaco, Niederlande, Portugal, Azoren, Madeira, San Marino, Slowakei, Slowenien, Spanien und ' +
                'die Kanarischen Inseln.'),
        next_sepaTerms: yup.boolean()
            .oneOf([true], 'Bitte akzeptieren Sie das SEPA-Mandat.'),
    });
